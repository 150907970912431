import Image from 'next/future/image'
import Script from 'next/script'
import { useContext } from 'react'
import { PixelContext } from '../providers/PixelContext'

const Pixel = () => {
  const { dispatch } = useContext(PixelContext)

  return (
    <>
      <Script
        src="/analytics/pixel.js"
        strategy="afterInteractive"
        onLoad={() => {
          dispatch({ type: 'SET_FBQ', payload: () => window.fbq })
        }}
      />

      <noscript>
        <Image
          height="1"
          width="1"
          className="hidden"
          alt="pixel"
          src="https://www.facebook.com/tr?id=1232161543654462&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  )
}

export default Pixel
